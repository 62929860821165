import Vue from "vue";

const route = "/payment/savepayment";

const success = response => {
  const { data } = response;

  return data;
};

const failure = error => {
  const { response } = error;

  return response;
};

export default savepayment => {
  return Vue.$http
    .post(`${route}`, savepayment)
    .then(response => success(response))
    .catch(error => failure(error));
};

<template>
  <div id="easypay-form" class="easypay"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SavePaymentService from "@/services/save-payment";
import { startCheckout } from "@easypaypt/checkout-sdk";

export default {
  name: "easypay",
  data() {
    return {
      donationValue: 0,
    };
  },
  computed: {
    ...mapGetters({
      getCheckout: "checkout/getCheckout",
      getCheckoutResponse: "checkout/getCheckoutResponse",
      getMainConfig: "partner/getMainConfig",
    }),
  },
  methods: {
    ...mapActions({
      setAuxValue: "partner/setAuxValue",
    }),
    scriptLoaded() {
      let successfulPaymentInteraction = false;
      let that = this;

      async function mySuccessHandler(data) {
        successfulPaymentInteraction = true;
        // Previous modal.onComplete behaviour
        try {
          await SavePaymentService.postSavePayment(data);
          that.setAuxValue(data.value);
        } catch (e) {
          console.log("OnComplete Error", e);
        }
      }

      function myCloseHandler(e) {
        if (successfulPaymentInteraction) {
          // Previous modal.onClose behaviour
          that.$router.push(that.getMainConfig.partner.partnerUrl);
        } else {
          // Previous modal.onCancel behaviour
          console.log("OnCancel", e);
          that.$router.push(that.getMainConfig.partner.partnerUrl);
        }
      }

      console.log("we are: ", process.env.VUE_APP_TESTING);
      // eslint-disable-next-line no-undef
      startCheckout(this.getCheckoutResponse, {
        id: "easypay-form",
        display: "inline",
        testing: process.env.VUE_APP_TESTING !== "false",
        onSuccess: mySuccessHandler,
        onClose: myCloseHandler,
      });
    },
  },
  created() {
    let checkoutJsScript = document.createElement("script");
    checkoutJsScript.setAttribute("src", this.getMainConfig.js);
    document.body.appendChild(checkoutJsScript);

    checkoutJsScript.addEventListener("load", this.scriptLoaded, false);
  },
};
</script>

<style scoped>
.easypay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
